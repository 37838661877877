import React, { useState } from 'react';

const DetailsCarousel = ({ item, receivedDetails }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const detailsArray = item.request_ids.map(requestId => receivedDetails[requestId]).filter(detail => detail);
    const hasMultipleDetails = detailsArray.length > 1;
    console.log(detailsArray);


    const handlePrev = () => {
        setActiveIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : detailsArray.length - 1
        );
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) =>
            prevIndex < detailsArray.length - 1 ? prevIndex + 1 : 0
        );
    };

    return (
        <div className="expanded-content">
            <h3>Additional Information</h3>
            <div className="carousel-container">
                {detailsArray.map((details, index) => (
                    <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={details.id || index}>
                        <h4>Details for Request ID: {details.Order_ID}</h4>
                        <p><strong>Order ID:</strong> {details.Order_ID}</p>
                        <p><strong>Tracking Number:</strong> {details.Tracking_Number}</p>
                        <p><strong>Package Type:</strong> {details.Package_Type}</p>
                        <p><strong>Warehouse Location:</strong> {details.Warehouse_Location}</p>
                        <p><strong>Actual Weight:</strong> {details.Actual_Weight}</p>
                        <p><strong>Volumetric Weight:</strong> {details.Volumetric_Weight}</p>
                        <p><strong>Dimensions:</strong> {`Height: ${details.Height}, Width: ${details.Width}, Length: ${details.Length}`}</p>
                    </div>
                ))}
            </div>
            {hasMultipleDetails && (
                <div className="carousel-controls">
                    <button className="button" onClick={handlePrev}>Prev</button>
                    <button className="button" onClick={handleNext}>Next</button>
                </div>
            )}
        </div>
    );
};

export default DetailsCarousel;
