import React, { useState } from "react";
import "./rejectModal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RejectModal = ({ isOpen, onClose, onSubmit }) => {
  const [remark, setRemark] = useState("");

  const handleSubmit = () => {
    if (!remark) {
      toast.warning("Remark is required to reject the shipment.");
      return;
    }
    onSubmit(remark); // Pass the remark back to the parent component
    setRemark(""); // Clear the remark input
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h3>Reject Shipment</h3>
        <textarea
          placeholder="Enter the reason to reject"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        ></textarea>
        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default RejectModal;
