import React, { useState } from "react";
import "./signUp.css";
import { useNavigate } from "react-router-dom";
import { db, auth, storage } from "../../firebase/Firebase"; // Import Firebase auth, db, and storage
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import { createUserWithEmailAndPassword } from "firebase/auth"; // Firebase Authentication
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage methods
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const [step, setStep] = useState(1); // Step state (1 or 2)
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [NIC, setNIC] = useState("");
  const [nicFrontImage, setNicFrontImage] = useState(null);
  const [nicBackImage, setNicBackImage] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0); // Password strength state
  const [loading, setLoading] = useState(false); // Loading state for showing loader
  const navigate = useNavigate();

  const [isToggled, setIsToggled] = useState(false);

// Ensure toggle state is correctly managed
const handleToggle = () => {
  setIsToggled((prev) => !prev);
};

  // Predefined address to add for every new user
  const predefinedAddress = {
    addressLine1: "Spedicon Maldives Pvt Ltd",
    addressLine2: "4 Omadu Faanu",
    addressLine3: "7th Floor Doorbell 701a,",
    addressLine4: "Haveeree Hingun, Maafanu",
    addressLine5: "20280 Male",
    country: "Republic Of Maldives",
  };

  // Function to generate a random User ID with 4 numbers and 2 characters
  const generateUserId = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
    const randomChars =
      String.fromCharCode(65 + Math.floor(Math.random() * 26)) + // First random letter
      String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Second random letter
    return `${randomNumber}${randomChars}`;
  };

  // Function to evaluate password strength
  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 25; // Password length >= 8
    if (/[A-Z]/.test(password)) strength += 25; // Contains uppercase letters
    if (/[0-9]/.test(password)) strength += 25; // Contains numbers
    if (/[\W]/.test(password)) strength += 25; // Contains special characters

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    evaluatePasswordStrength(value);
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (!name || !contact || !email || !password || !confirmPassword) {
      toast.error("Please fill in all required fields!");
      return;
    }
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters!");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    if (contact.length !== 7) {
      toast.error("Contact number must be 7 digits without the country code (960)!");
      return;
    }
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!NIC || !nicFrontImage || !nicBackImage) {
      toast.error("Please complete all fields in this step!");
      return;
    }
    if (NIC.length !== 13) {
      toast.error("NIC Number must be 13 digits!");
      return;
    }

    setLoading(true); // Start loading
    try {
      // Step 1: Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Get the created user (Firebase UID)

      // Use user.uid directly instead of auth.currentUser
      const customUserId = generateUserId();

      // Step 2: Upload NIC images to Firebase Storage
      const uploadImage = async (image, path) => {
        try {
          const imageRef = ref(storage, path);
          await uploadBytes(imageRef, image);
          return await getDownloadURL(imageRef);
        } catch (err) {
          toast.error("Failed to upload image: " + err.message);
          throw err;
        }
      };

      const nicFrontUrl = await uploadImage(nicFrontImage, `users/${user.uid}/nicFront.jpg`);
      const nicBackUrl = await uploadImage(nicBackImage, `users/${user.uid}/nicBack.jpg`);

      // Step 3: Store additional user data in Firestore with the custom User ID
      await setDoc(doc(db, "users", user.uid), {
        User_ID: customUserId,
        "User Name": name,
        "Mobile Number": contact,
        Email: email,
        Address: predefinedAddress,
        NIC: NIC,
        NIC_Front_Image: nicFrontUrl,
        NIC_Back_Image: nicBackUrl,
      });

      toast.success("Registration successful!");
      setTimeout(() => {
        setLoading(false);
        navigate("/signin"); // Redirect to sign-in page after successful sign-up
      }, 2000);
    } catch (error) {
      // Enhanced error handling for Firebase Authentication
      if (error.code === "auth/invalid-email") {
        toast.error("Invalid email format!");
      } else if (error.code === "auth/weak-password") {
        toast.error("Password must be at least 6 characters!");
      } else if (error.code === "auth/email-already-in-use") {
        toast.error("This email is already registered!");
      } else {
        toast.error(error.message || "An unknown error occurred!");
      }
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="back-button-container" title="Back to SignIn">
        <img
          src={process.env.PUBLIC_URL + "/images/backBtn.png"}
          alt="back button"
          className="top-right-back-button"
          onClick={() => navigate("/signin")}
        />
      </div>
      <div className="signup-form">
        <center>
          <img
            src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp"
            alt="Fits e-Parcel Maldives Logo"
            className="logo-image"
          />
        </center>
        <h2>Sign up - {step === 1 ? "Step 1" : "Step 2"}</h2>
        {step === 1 && (
          <form onSubmit={handleNext}>
            <div className="input-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>Contact number</label>
              <div className="phone-input">
                <span>+960</span>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="input-group">
              <label>Email address</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <div className="password-strength-bar">
                <div
                  className={`password-strength-level level-${passwordStrength}`}
                  style={{ width: `${passwordStrength}%` }}
                ></div>
              </div>
            </div>
            <div className="input-group">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="next-btn">
              Next
            </button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleSignUp}>
            <div className="input-group">
              <label>National ID Card Number</label>
              <input
                type="text"
                placeholder="Enter NIC number"
                value={NIC}
                onChange={(e) => setNIC(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>National ID Card Front Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setNicFrontImage(e.target.files[0])}
                required
              />
            </div>
            <div className="input-group">
              <label>National ID Card Back Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setNicBackImage(e.target.files[0])}
                required
              />
            </div>
            <button type="button" className="back-btn" onClick={handleBack}>
              Back
            </button>
            <button type="submit" className="signup-btn" disabled={loading}>
              {loading ? "Signing up..." : "Sign Up"}
            </button>
          </form>
        )}
      </div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SignUp;
