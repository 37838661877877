import React, { useState, useEffect } from "react";
import './UsersTab.css'; 

const UsersTab = ({ allUsers }) => {
  const [userSearch, setUserSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(allUsers || []);
  const [selectedUser, setSelectedUser] = useState(null);

  // Real-time search filtering
  useEffect(() => {
    if (!allUsers) return; // Avoid errors when `allUsers` is undefined
    const searchQuery = userSearch.toLowerCase();
    const filtered = allUsers.filter(
      (user) =>
        user.User_ID.toLowerCase().includes(searchQuery) ||
        user["User Name"].toLowerCase().includes(searchQuery)
    );
    setFilteredUsers(filtered);
  }, [userSearch, allUsers]);

  return (
    <div className="admin-section">
      <h2>Users</h2>
      <div className="user-search">
        <input
          type="text"
          placeholder="Search by User ID or User Name"
          value={userSearch}
          onChange={(e) => setUserSearch(e.target.value)}
        />
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Addresses</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <tr key={user.User_ID}>
                <td>{user.User_ID}</td>
                <td>{user["User Name"]}</td>
                <td>{user.Email}</td>
                <td>{user["Mobile Number"]}</td>
                <td>
                  {user.shippingAddresses && user.shippingAddresses.length > 0 ? (
                    <div>
                      <div className="viewAddresses">
                      <button 
                        className="viewAddressBtn"
                        onClick={() =>
                          setSelectedUser((prevSelectedUser) =>
                            prevSelectedUser === user.User_ID
                              ? null
                              : user.User_ID
                          )
                        }
                      >
                        {selectedUser === user.User_ID
                          ? "Hide Addresses"
                          : "View All Addresses"}
                      </button>
                      </div>
                      {selectedUser === user.User_ID && (
                        <table className="inner-table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Address</th>
                              <th>Contact Number</th>
                              <th>Country</th>
                              <th>Post Code</th>
                              <th>Receiver Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.shippingAddresses.map((address, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{address.address}</td>
                                <td>{address.contactNumber}</td>
                                <td>{address.country}</td>
                                <td>{address.postCode}</td>
                                <td>{address.receiverName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ) : (
                    <span>No addresses available</span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTab;
