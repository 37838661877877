import React, { useState, useEffect } from "react";
import './shopOnline.css'; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { storage, db } from "../../firebase/Firebase"; // Ensure you import Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";


const ShopOnlineModal = ({ isShopOnlineModalOpen, toggleModal, userId }) => {
  const [step, setStep] = useState(1); // Track the current step (1 or 2)
  const [openConfirmation, setOpenConfirmation] = useState(false); // State to manage confirmation dialog visibility
  
  //Loader check
  const [isLoading, setIsLoading] = useState(false);


  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  
  // Step 1 fields
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [receivingDate, setReceivingDate] = useState("");
  const [warehouseLocation, setWarehouseLocation] = useState("");

  // Step 2 fields
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [trackingNo, setTrackingNo] = useState("");
  const [invoice, setInvoice] = useState(null);

  // Progress state for file upload
  const [uploadProgress, setUploadProgress] = useState(0);
  const [toastTriggered, setToastTriggered] = useState(false); // Flag to track if the toast was shown

  // Handle Next Step
  const handleNext = () => {
    if (!itemName || !description || !platform || !orderDate || !receivingDate || !warehouseLocation) {
      toast.error("Please fill in all the fields before proceeding.");
      return;
    }
    setStep(2);
  };

  // Handle Back Step
  const handleBack = () => {
    setStep(1);
  };

  // Handle Submit
  const handleSubmit = async () => {
    if (!trackingNo || !invoice) {
      toast.error("Tracking No or the invoice is missing. Check again!");
      return;
    }
    setIsLoading(true); // Show the loader
    try {
      // Upload the invoice file and get the URL
      const invoiceUrl = invoice ? await uploadInvoice(invoice) : null;
  
      // Combine all form data
      const formData = {
        Customer_ID: userId,
        itemName,
        description,
        platform,
        orderDate,
        status: "Pending",
        receivingDate,
        warehouseLocation,
        specialInstructions,
        trackingNo,
        invoiceUrl, // Include the uploaded invoice URL
      };
  
      // Save the data to Firestore
      const shopOnlineRef = collection(db, "ShopOnline");
      await addDoc(shopOnlineRef, formData);
  
      toast.success("Form successfully submitted!");
    // Delaying closing the modal
        setTimeout(() => {
          toggleModal();
        }, 1500);
      
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit the form. Please try again.");
    } finally {
      setIsLoading(false); // Hide the loader
    }
  };
  


  // Method to upload invoice to Firebase Storage
  const uploadInvoice = async (file) => {
    if (!file) return null;

    const storageRef = ref(storage, `ShopOnline/invoices/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
      );
    });
  };


  // Simulate file upload progress
  const handleFileUpload = (file) => {
    setInvoice(file); // Set the file
    setUploadProgress(0); // Reset progress
    setToastTriggered(false); // Reset toast flag to avoid multiple toasts

    // Simulate a file upload with progress
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval); // Stop the interval when progress reaches 100
          return 100; // Cap progress at 100%
        }
        return prevProgress + 10; // Increment progress by 10%
      });
    }, 300); // Simulate progress every 300ms
  };

 
  // UseEffect to trigger the toast once when upload completes
  useEffect(() => {
    if (uploadProgress >= 100 && !toastTriggered) {
      toast.success("File uploaded successfully!", {
        autoClose: 1700, // Close after 1.7 seconds
        pauseOnHover: false, // Prevent pausing on hover
        closeOnClick: true, // Close on click
      });
      setToastTriggered(true); // Set the toast flag to true
    }
  }, [uploadProgress, toastTriggered]); // Run whenever uploadProgress or toastTriggered changes

  // Show confirmation dialog when Close button is clicked
  const handleClose = () => {
    setOpenConfirmation(true); // Open the confirmation dialog
  };

  // Handle confirmation dialog close actions
  const handleConfirmClose = (confirm) => {
    if (confirm) {
      console.log('Closing modal');
      toggleModal();  // Close the modal (ShopOnlineModal)
    } else {
      console.log('Modal not closed');
      setOpenConfirmation(false); // Hide the confirmation dialog without closing the modal
    }
  };

  // Prevent typing in the date field by adding event listeners to the input fields
  const preventTypingInDate = (e) => {
    e.preventDefault(); // Prevent manual input
  };


  useEffect(() => {
    console.log("userId passed to ShopOnlineModal:", userId);
  }, [userId]);
  
  // Preventing typing using useEffect (for mounted state)
  useEffect(() => {
    const dateInputs = document.querySelectorAll("input[type='date']");
    dateInputs.forEach((input) => {
      input.addEventListener("keydown", preventTypingInDate); // Disable typing in date inputs
    });

    // Cleanup listener on component unmount
    return () => {
      dateInputs.forEach((input) => {
        input.removeEventListener("keydown", preventTypingInDate);
      });
    };
  }, []); // Run only once when the component mounts

  if (!isShopOnlineModalOpen) return null; // Don't render if not open

  return (
    <div className="Onlinemodal-overlay">
      {isLoading && (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    )}
      <div className="Onlinemodal-details">
        {/* Close Button */}
        <button className="close-modal" onClick={handleClose}>
          ×
        </button>
        <h2>Shop Online</h2>

        <p className="steps-indicator" style={{ fontSize: "23px", fontWeight: "600", color: "#385E88", marginBottom: "30px" }}>
          Step {step} of 2
        </p>

        {step === 1 ? (
          <div className="Onlineform-group">
            <p className="name">Item Name</p>
            <input type="text" placeholder="Please give a Name (e.g. Nike order)" value={itemName} onChange={(e) => setItemName(e.target.value)} required />
            <p className="description">Description</p>
            <input type="text" placeholder="Enter item description" value={description} onChange={(e) => setDescription(e.target.value)} required />
            <p className="platform">Platform ordered from</p>
            <input type="text" placeholder="Enter the platform you ordered the item from" value={platform} onChange={(e) => setPlatform(e.target.value)} required />
            <p className="Orderdate">Ordered Date</p>
            <input 
              type="date" 
              style={{ cursor: "pointer" }} 
              max={today} 
              value={orderDate} 
              onChange={(e) => setOrderDate(e.target.value)} 
              required 
            />
            <p className="Receivingdate">Receiving Date</p>
            <input 
              type="date" 
              style={{ cursor: "pointer" }} 
              value={receivingDate} 
              onChange={(e) => setReceivingDate(e.target.value)} 
              required 
              min={today}  // Set the minimum date for receiving date to today
            />
            <p className="warehouse">Warehouse Location</p>
            <select value={warehouseLocation} onChange={(e) => setWarehouseLocation(e.target.value)} required>
              <option value="">Select a location</option>
              <option value="Australia">Australia</option>
              <option value="Canada">Canada</option>
              <option value="China">China</option>
              <option value="India">India</option>
              <option value="Japan">Japan</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Singapore">Singapore</option>
              <option value="UAE">UAE</option>
              <option value="UK">UK</option>
              <option value="USA">USA</option>
            </select>
            <div className="nextClose-container">
              <button className="nextPageBtn" onClick={handleNext}>Next</button>
              <button className="closePageBtn" onClick={handleClose}>Close</button>
            </div>
          </div>
        ) : (
          <div className="Onlineform-group">
            <p className="special-instructions">Special Instructions</p>
            <input placeholder="Enter any special instructions" value={specialInstructions} onChange={(e) => setSpecialInstructions(e.target.value)} />
            <p className="tracking-no">Tracking No</p>
            <input type="text" placeholder="Enter tracking number" value={trackingNo} onChange={(e) => setTrackingNo(e.target.value)} />
            <p className="invoice">Invoice</p>
            <input
              type="file"
              onChange={(e) => handleFileUpload(e.target.files[0])}
              required
            />
            {uploadProgress > 0 && (
              <div className="upload-progress">
                <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                <p>{uploadProgress}%</p>
              </div>
            )}
            <div className="nextClose-container">
              <button className="prevPageBtn" onClick={handleBack}>Back</button>
              <button
                  className="submitFromBtn"
                  onClick={handleSubmit}
                  disabled={uploadProgress > 0 && uploadProgress < 100}
                >
                  {uploadProgress > 0 && uploadProgress < 100 ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
      
      {/* Material UI Confirmation Dialog */}
      <Dialog 
        open={openConfirmation} 
        onClose={() => setOpenConfirmation(false)}
        TransitionProps={{ timeout: 500 }}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: '35px',
            borderTop: '7px solid #0056B3',
            padding: '20px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
        }}>
        <DialogTitle style={{ fontFamily: "Segoe UI", fontSize: "28px" }}>Confirm to Close</DialogTitle>
        <DialogContent>
          You might lose unsaved data.<br/>Are you sure you want to close the form?
        </DialogContent>
        <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button 
            style={{
              backgroundColor: "#0056B3", 
              borderRadius: "25px", 
              color: "white", 
              textTransform: "none", 
              fontFamily: "Segoe UI", 
              transition: "background-color 0.3s ease"
            }}
            onClick={() => handleConfirmClose(true)} 
            color="primary">
            Yes
          </Button>
          <Button 
            style={{
              backgroundColor: "#DD3F3F", 
              borderRadius: "25px", 
              color: "white", 
              textTransform: "none", 
              fontFamily: "Segoe UI"
            }}
            onClick={() => handleConfirmClose(false)} 
            color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
};

export default ShopOnlineModal;
