import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';
import { v4 as uuidv4 } from 'uuid';
import './Admin_Creation.css'; 

const AdminCreation = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('+94');
  const [userRoles, setUserRoles] = useState([]);
  const [password, setPassword] = useState('');
  const [Location , setLocation] = useState('');

  useEffect(() => {
    if (isOpen) {
      const generatedPassword = generatePassword();
      setPassword(generatedPassword);
    }
  }, [isOpen]);

  const handleRoleChange = (role) => {
    setUserRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  const generatePassword = () => {
    return uuidv4().slice(0, 12); // Generate a random password (12 characters)
  };

  const handleRegister = async () => {
    if (!password) {
      setPassword(generatePassword());
    }
    const newAdmin = {
      firstName,
      lastName,
      email,
      mobileNumber,
      userRoles,
      password,
    };

    try {
      const adminUsersCollectionRef = collection(db, 'Admin_Users');
      await addDoc(adminUsersCollectionRef, newAdmin);
      alert('Admin user successfully registered!');
      setFirstName('');
      setLastName('');
      setEmail('');
      setMobileNumber('+94');
      setUserRoles([]);
      onClose();
    } catch (error) {
      console.error('Error registering admin user:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Create User Account</h2>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Mobile Number</label>
          <input
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>

                <div className="form-group">
        <label>Location</label>
        <select value={Location} onChange={(e) => setLocation(e.target.value)}>
            <option value="">Select Warehouse Location</option>
            <option value="Australia">Australia</option>
            <option value="Canada">Canada</option>
            <option value="China">China</option>
            <option value="India">India</option>
            <option value="Japan">Japan</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Singapore">Singapore</option>
            <option value="UAE">UAE</option>
            <option value="UK">UK</option>
            <option value="USA">USA</option>
        </select>
        </div>


        <div className="form-group">
          <label>Password</label>
          <input
            type="text"
            value={password}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>User Role</label>
          <div className="user-roles">
            {['SuperAdmin', 'CourierOps', 'Finance', 'CSTC', 'Wharf', 'Cashier', 'Credit Control', 'eParcel', 'Couriers', 'Special instructions', 'D/n View', 'DTN Hawb View', 'Admin', 'DTN Awb Print', 'DevAllModuleReadOnly', 'Detain Awb Preview only', 'OPS', 'Edit Shipment info', 'View shipment info', 'CS to view DTN HAWB', 'View pickup only'].map((role) => (
              <div key={role}>
                <input
                  type="checkbox"
                  checked={userRoles.includes(role)}
                  onChange={() => handleRoleChange(role)}
                />
                <label>{role}</label>
              </div>
            ))}
          </div>
        </div>
        <button className='btnRegister' onClick={handleRegister}>Register</button>
      </div>
    </div>
  );
};

export default AdminCreation;