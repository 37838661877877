import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { auth, db } from './firebase/Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import SignIn from './pages/signin_page/signin';
import SignUp from './pages/SignUp/SignUp';
import Dashboard from './pages/Dashboard_page/Dashboard';
import Header from './components/Header/Header';
import Profile from './pages/Profile_page/Profile';
import CheckRates from './pages/Check_Rates_Page_Without_User/Check_rates';
import Archive from './pages/Archived_Shipments_Page/Archived_Shipments';
import AdminPanel from './pages/Admin_Page/AdminPanel';
import ResetPassword from './pages/Reset_password_Page/Reset_password';
import './index.css';
import Address from './pages/Address_Book_Page/address';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout({ children }) {
    const location = useLocation();
    const hideHeaderRoutes = ['/signin', '/signup', '/Reset-password', '/check-rates'];

    return (
        <>
            {!hideHeaderRoutes.includes(location.pathname) && <Header />}
            {children}
        </>
    );
}

// Protected Route Component
const ProtectedRoute = ({ children, isAdminRoute, user }) => {
    if (!user) {
        return <Navigate to="/signin" />;
    }
    if (isAdminRoute && !user.isAdmin) {
        return <Navigate to="/dashboard" />;
    }
    if (!isAdminRoute && user.isAdmin) {
        return <Navigate to="/admin" />;
    }
    return children;
};

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                const adminUsersRef = collection(db, "Admin_Users");
                const adminQuery = query(adminUsersRef, where("email", "==", currentUser.email));
                const adminSnapshot = await getDocs(adminQuery);

                setUser({
                    ...currentUser,
                    isAdmin: !adminSnapshot.empty,
                });
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/check-rates" element={<CheckRates/>} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/Reset-password" element={<ResetPassword />} />

                {/* Protected Admin Route */}
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAdminRoute={true} user={user}>
                            <AdminPanel />
                        </ProtectedRoute>
                    }
                />

                {/* Protected Routes for Normal Users */}
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute isAdminRoute={false} user={user}>
                            <Layout>
                                <Dashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute isAdminRoute={false} user={user}>
                            <Layout>
                                <Profile />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/address-book"
                    element={
                        <ProtectedRoute isAdminRoute={false} user={user}>
                            <Layout>
                                <Address />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/archived-shipments"
                    element={
                        <ProtectedRoute isAdminRoute={false} user={user}>
                            <Layout>
                                <Archive />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                {/* Default Route */}
                <Route path="/" element={<Navigate to="/signin" />} />
            </Routes>
            <ToastContainer />
        </Router>
    );
}

export default App;
