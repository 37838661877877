import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase/Firebase";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  updateDoc,
  Timestamp,
  getDocs,
} from "firebase/firestore";
import { storage } from "../../firebase/Firebase"; // Import storage from Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import necessary methods for file upload
import Footer from "../../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShipYourItem from "../../components/Shiping_popup/Ship_your_Item.jsx";
import ShopOnlineModal from "../../components/Shop_Online_Modal/shopOnline.jsx";
import DetailsCarousel from "./Details_Carousel";



const Dashboard = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(""); // For tab toggling
  const [userAuthenticated, setUserAuthenticated] = useState(false); // Track user authentication status
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Received");
  const [availableItems, setAvailableItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShoppingRequestModalOpen, setIsShoppingRequestModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [items, setItems] = useState([{ id: 1, url: "", Description: "" }]);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [Volumetric, setVolumetric] = useState("");
  const [address, setAddress] = useState({});
  const [shoppingRequests, setShoppingRequests] = useState([]);
  const [shoppingRequestName, setShoppingRequestName] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [receiptFile, setReceiptFile] = useState(null);
  const [progress, setProgress] = useState(0); // Upload Progress Bar
  const [selectedRequestIds, setSelectedRequestIds] = useState([]);
  const [receivedPackages, setReceivedPackages] = useState([]); // State for received packages
  const [shopOnlineItems, setShopOnlineItems] = useState({});
  const [loading, setLoading] = useState(true); //Adding loading
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [selectedStatusShop, setSelectedStatusShop] = useState('');  // State to track the selected status
  const [shippingItems, setShippingItems] = useState([]);
  const [receivedDetails, setReceivedDetails] = useState({});
  const itemsPerPage = 10; // Number of items to display per page
  const totalPagesPackages = Math.ceil(shippingItems.length / itemsPerPage); // Dynamically calculate total pages
  const pageNumbersPackages = Array.from({ length: totalPagesPackages }, (_, i) => i + 1); // Generate page numbers
  const [isShopOnlineModalOpen, setIsShopOnlineModalOpen] = useState(false);
  const [itemCounts, setItemCounts] = useState({}); // Add this to your component
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [filteredItems, setFilteredItems] = useState(shopOnlineItems);


  //Filtering shipment table
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [warehouseFilter, setWarehouseFilter] = useState("All");
    const [filteredShippingData, setFilteredShippingData] = useState(shippingItems);

  //realtime user search and date filter
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
   // Toggle function for the modal
   const toggleShopOnlineModal = () => {
    setIsShopOnlineModalOpen(prevState => !prevState);
  };
    

   // Define fetchBankDetails
   const fetchBankDetails = async () => {
    const bankCollectionRef = collection(db, "bank_Details");
    const bankSnapshot = await getDocs(bankCollectionRef);
    const banks = bankSnapshot.docs.map((doc) => doc.data());
    setBankDetails(banks);
  };

  // Define fetchReceivedPackages
  const fetchReceivedPackages = async () => {
    const user = auth.currentUser;
    if (user) {
      const userId = (await getDoc(doc(db, "users", user.uid))).data()?.User_ID;
      if (userId) {
        const q = query(collection(db, "Received_packages"), where("Customer_ID", "==", userId));
        const snapshot = await getDocs(q);
        const packages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setReceivedPackages(packages);
      }
    }
  };

  // Define fetchShoppingRequests
  const fetchShoppingRequests = async () => {
    const user = auth.currentUser;
    if (user) {
      const userId = (await getDoc(doc(db, "users", user.uid))).data()?.User_ID;
      if (userId) {
        const q = query(collection(db, "shopping_Requests"), where("customer_id", "==", userId));
        const snapshot = await getDocs(q);
        const requests = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setShoppingRequests(requests);
      }
    }
  };

// Filter requests based on the search term
const filteredRequests = shoppingRequests.filter((request) =>
  request.Request_name.toLowerCase().includes(searchTerm.toLowerCase())
);

const [currentPage, setCurrentPage] = useState(1); // Track the current page
const cardsPerPage = 6; // Display 6 cards per page

//Filtering Logic for shipment table on my shipments
useEffect(() => {
  const applyFilters = () => {
    let filteredData = shippingItems;

    // Filter by date range
    if (startDate) {
      filteredData = filteredData.filter((item) =>
        new Date(item.createdAt.seconds * 1000) >= new Date(startDate)
      );
    }
    if (endDate) {
      filteredData = filteredData.filter((item) =>
        new Date(item.createdAt.seconds * 1000) <= new Date(endDate)
      );
    }

    // Filter by warehouse
    if (warehouseFilter !== "All") {
      filteredData = filteredData.filter(
        (item) => item.warehouse_location === warehouseFilter
      );
    }

    setFilteredShippingData(filteredData);
  };

  applyFilters();
}, [startDate, endDate, warehouseFilter, shippingItems]);



useEffect(() => {
  setCurrentPage(1); // Reset to the first page when search term or status changes
}, [searchTerm, selectedStatusShop]);

// Filter and sort requests based on search and status filters
const filteredAndSortedRequests = filteredRequests
  .filter((request) => selectedStatusShop === "" || request.Status === selectedStatusShop)
  .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

// Calculate total pages and displayed item range
const totalPages = Math.ceil(filteredAndSortedRequests.length / cardsPerPage);
const startIndex = (currentPage - 1) * cardsPerPage + 1;
const endIndex = Math.min(currentPage * cardsPerPage, filteredAndSortedRequests.length);

// Slice the filtered requests to display only the current page
const currentRequests = filteredAndSortedRequests.slice(
  (currentPage - 1) * cardsPerPage,
  currentPage * cardsPerPage
);

  const toggleExpand = (itemId) => {
    if (expandedItemId === itemId) {
      setExpandedItemId(null);
    } else {
      setExpandedItemId(itemId);
    }
  };

const generatePageNumbers = (currentPage, totalPages) => {
  const pageNumbers = [];

  // Always show the first page
  if (currentPage > 3) {
    pageNumbers.push(1);
    if (currentPage > 4) {
      pageNumbers.push("...");
    }
  }

  // Pages around the current page
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
    pageNumbers.push(i);
  }

  // Always show the last page
  if (currentPage < totalPages - 2) {
    if (currentPage < totalPages - 3) {
      pageNumbers.push("...");
    }
    pageNumbers.push(totalPages);
  }

  return pageNumbers;
};
const pageNumbers = generatePageNumbers(currentPage, totalPages);


//loading state when reaching dashboard
useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true); // Show loader
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData["User Name"]);
          setUserId(userData["User_ID"]);
          setAddress(userData.Address);

          // Fetch other required data here
          await fetchBankDetails();
          await fetchReceivedPackages();
          await fetchShoppingRequests();
        } else {
          console.log("No such document!");
        }
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  fetchData();
}, [navigate]);



  // Fetch bank details when the component mounts
  useEffect(() => {
    const fetchBankDetails = async () => {
      const bankCollectionRef = collection(db, "bank_Details");
      const bankSnapshot = await getDocs(bankCollectionRef);
      const banks = bankSnapshot.docs.map((doc) => doc.data());
      setBankDetails(banks);
    };

    fetchBankDetails();
  }, []);

// State to track selected warehouse location
const [selectedLocation, setSelectedLocation] = useState('');

// Fetch Received Packages filtered by Customer_ID and Warehouse_Location
useEffect(() => {
  const fetchReceivedPackages = async () => {
    if (userId) {
      let q = query(collection(db, 'Received_packages'), where('Customer_ID', '==', userId));

      // Apply Warehouse_Location filter only if selected
      if (selectedLocation) {
        q = query(q, where('Warehouse_Location', '==', selectedLocation));
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const packages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReceivedPackages(packages);

        // Calculate the package counts by location
        const counts = calculateLocationCounts(packages);
        setLocationCounts(counts); // Update the locationCounts state
      });


      return () => unsubscribe();
    }
  };

  fetchReceivedPackages();
}, [userId, selectedLocation]); // Re-run when userId or selectedLocation changes




useEffect(() => {
  const fetchShopOnline = async () => {
    if (userId) {
      let q = query(collection(db, 'ShopOnline'), where('Customer_ID', '==', userId));

      // Apply Warehouse_Location filter only if selected
      // if (selectedLocation) {
      //   q = query(q, where('warehouseLocation', '==', selectedLocation));
      // }
      //
      // // Apply platform filter only if selected
      // if (selectedPlatform) {
      //   q = query(q, where('platform', '==', selectedPlatform));
      // }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const shopOnlineItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShopOnlineItems(shopOnlineItems);

        // Calculate additional details if needed
        const counts = calculateItemCountsByLocation(shopOnlineItems);
        setItemCounts(counts); // Update the state with calculated counts
      });

      return () => unsubscribe();
    }
  };

  fetchShopOnline();
}, [userId, selectedLocation]); // Re-run when userId, selectedLocation, or selectedPlatform changes


const calculateItemCountsByLocation = (items) => {
  return items.reduce((acc, item) => {
    const location = item.warehouseLocation || 'Unknown';
    acc[location] = (acc[location] || 0) + 1;
    return acc;
  }, {});
};




  const handleFooterClick = (item) => {
    if (activeTab === item) {
      setActiveTab(""); // Close the tab if clicked again
    } else {
      setActiveTab(item); // Open the clicked tab
    }
  };

  const toggleBodyScrolling = (disableScroll) => {
    document.body.style.overflow = disableScroll ? "hidden" : "auto";
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    toggleBodyScrolling(!isModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    toggleBodyScrolling(!isEditModalOpen);
  };

  const toggleShoppingRequestModal = () => {

    setItems([{ id: 1, url: "", Description: "" }]);
    setIsShoppingRequestModalOpen(!isShoppingRequestModalOpen);
    toggleBodyScrolling(!isShoppingRequestModalOpen);
  };


  const addItem = () => {
    setItems([...items, { id: items.length + 1, url: "", Description: "" }]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
    // Show error feedback for empty fields
  if (value.trim() === "") {
    toast.warning(`Field "${field}" cannot be empty.`);
  }
  };


  const deleteItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleSubmitRequestToPurchase = async () => {
    // Check if all items fields are filled
    const allFieldsFilled = items.every(item => item.url !== '' && item.Description !== '');
    if (!allFieldsFilled) {
      toast.error("Please fill in all required fields");
      return;
    }

    // Check if terms are agreed
    if (!agreedToTerms) {
      toast.warning("Please agree to the terms and conditions");
      return;
    }

    try {
      setLoading(true); // Show loader

      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const userId = userData.User_ID;

          const shoppingCollectionRef = collection(db, "shopping_Requests");
          const createdAt = Timestamp.now();

          await addDoc(shoppingCollectionRef, {
            Request_id: `REQ_${new Date().getTime()}`,
            customer_id: userId,
            Service_type: "Assisted Shopping",
            Status: "Pending",
            Weight: Number(weight),
            Length: Number(length),
            Width: Number(width),
            Height: Number(height),
            Volumetric_Weight: Number(Volumetric),
            Request_name: shoppingRequestName,
            product_reference_url: items.map((item) => item.url),
            Description: items.map((item) => item.Description),
            createdAt,
          });



          // Close modal immediately after successful submission
          toggleShoppingRequestModal();

          setTimeout(() => {
            toast.info("Shopping request successfully submitted and moved to 'Shopping' tab");
          }, 600);

        } else {
          console.error("User document does not exist.");
          toast.error("Could not retrieve user data. Please try again.");
        }
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error submitting shopping request:", error);
      toast.error("Error submitting shopping request. Please try again.");
    } finally {
      setLoading(false); // Hide loader in all cases
    }
  };



  const handleEditRequest = () => {
    if (selectedRequest) {
      setItems(
        selectedRequest.product_reference_url.map((url, index) => ({
          id: index + 1,
          url,
          Description: selectedRequest.Description[index],
        }))
      );
      toggleEditModal();
    }
  };



  const handleSaveChanges = async () => {

    // Validate if all URLs and Descriptions are filled
  const allFieldsFilled = items.every(
    (item) => item.url.trim() !== "" && item.Description.trim() !== ""
  );

  if (!allFieldsFilled) {
    toast.error("Please fill in all fields before saving.");
    return;
  }
    if (selectedRequest) {
      try {
        const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
        await updateDoc(requestDocRef, {
          product_reference_url: items.map((item) => item.url),
          Description: items.map((item) => item.Description),
          Status: "Pending", // Update the Status field to "Pending"
        });

        setSelectedRequest({
          ...selectedRequest,
          product_reference_url: items.map((item) => item.url),
          Description: items.map((item) => item.Description),
          Status: "Pending", // Update the local state for Status as well
        });

        toast.success("Purchase request has been successfully updated!");
        toggleEditModal();
      } catch (error) {
        console.error("Error updating purchase request:", error);
      }
    }
  };



// Packages popup handle
const [isPackagesPopupOpen, setIsPackagesPopupOpen] = useState(false);
// Function to handle opening the Packages popup
const handlePackagesButtonClick = () => {
  setIsPackagesPopupOpen(true);
};

// Function to handle closing the Packages popup
const handleClosePackagesPopup = () => {
  setIsPackagesPopupOpen(false);
};


  // Function to handle item selection
const handleItemSelect = (requestId) => {
  setSelectedRequestIds((prevIds) =>
    prevIds.includes(requestId)
      ? prevIds.filter((id) => id !== requestId) // Deselect item if already selected
      : [...prevIds, requestId] // Select item if not already selected
  );
};

// Function to handle "Request to Ship Your Items" button click
const handleRequestToShip = async () => {
  console.log(selectedRequestIds);
  if (selectedRequestIds.length === 0) {
    toast.warning("Please select at least one item to ship.");
    return;
  }

  try {
    const shippingCollectionRef = collection(db, "Shipping_items");
    const createdAt = Timestamp.now();

    // Add a new document to "Shipping_items" collection with the selected Request IDs
    await addDoc(shippingCollectionRef, {
      request_ids: selectedRequestIds,
      createdAt,
    });


    toast.success("Request to ship your items has been successfully submitted!");
    setSelectedRequestIds([]); // Clear selected items
  } catch (error) {
    console.error("Error adding document to Shipping_items:", error);
  }
};

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData["User Name"]);
          setUserId(userData["User_ID"]);
          setAddress(userData.Address);
        } else {
          console.log("No such document!");
        }
      } else {
        navigate("/signin");
      }
    };

    fetchUserData();
  }, [navigate]);



  // Fetch packages tab
  useEffect(() => {
    const fetchShippingItems = async () => {
      try {
        const shippingCollectionRef = collection(db, "Shipping_items");
        const q = query(shippingCollectionRef, where("Customer_ID", "==", userId));
        const querySnapshot = await getDocs(q);

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setShippingItems(items);
        setLoading(false);

        // Fetch related data from Received_packages
        for (const item of items) {
          if (item.request_ids && item.request_ids.length > 0) {
            for (const requestId of item.request_ids) {
              const receivedCollectionRef = collection(db, "Received_packages");
              const receivedQuery = query(receivedCollectionRef, where("Order_ID", "==", requestId));
              const receivedSnapshot = await getDocs(receivedQuery);

              if (!receivedSnapshot.empty) {
                receivedSnapshot.forEach((doc) => {
                  setReceivedDetails((prevDetails) => ({
                    ...prevDetails,
                    [requestId]: {
                      id: doc.id,
                      ...doc.data(),
                    },
                  }));
                });
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching shipping items:", error);
        setLoading(false);
      }
    };

    if (userId) {
      fetchShippingItems();
    }
  }, [userId]);


  // Get today's date in 'YYYY-MM-DD' format
const today = new Date().toISOString().split('T')[0];

  //Data filtering for shop Online orders
  useEffect(() => {
    const applyFilters = () => {
      let filteredData = shopOnlineItems;
  
      // Filter by date range
      if (startDate) {
        filteredData = filteredData.filter((item) =>
          new Date(item.orderDate) >= new Date(startDate)
        );
      }
      if (endDate) {
        filteredData = filteredData.filter((item) =>
          new Date(item.orderDate) <= new Date(endDate)
        );
      }
  
      // Filter by status
      if (statusFilter !== "All") {
        filteredData = filteredData.filter(
          (item) => item.status.toLowerCase() === statusFilter.toLowerCase()
        );
      }
  
      setFilteredItems(filteredData);
    };
  
    applyFilters();
  }, [startDate, endDate, statusFilter, shopOnlineItems]); // Run when filters or data change


  const resetFilters = () => {
    setStartDate(""); // Reset date filters
    setEndDate("");
    setStatusFilter("All"); // Reset status filter
    setWarehouseFilter("All");
    setFilteredShippingData(shippingItems);
    setFilteredItems(shopOnlineItems); // Reset filtered data to original data
    setCurrentPage(1); // Reset pagination to the first page
  };

  // Date filter change handlers
const handleStartDateChange = (e) => {
  const selectedStartDate = e.target.value;
  // Check if the selected start date is later than the end date
  if (endDate && new Date(selectedStartDate) > new Date(endDate)) {
    toast.warning("Start date cannot be later than End date");
    return;
  }
  setStartDate(selectedStartDate);
};

const handleEndDateChange = (e) => {
  const selectedEndDate = e.target.value;
  // Check if the selected end date is earlier than the start date
  if (startDate && new Date(selectedEndDate) < new Date(startDate)) {
    toast.warning("End date cannot be earlier than Start date");
    return;
  }
  setEndDate(selectedEndDate);
};

  // Fetch shopping requests ****
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const shoppingRequestsRef = collection(db, "shopping_Requests");
      const q = query(shoppingRequestsRef, where("customer_id", "==", userId));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const requests = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShoppingRequests(requests);
      });

      return () => unsubscribe();
    } else {
      navigate("/signin");
    }
  }, [navigate, userId]);

  // Handle receipt upload
  const handleReceiptUpload = () => {
    if (!receiptFile) {
      toast.warning("Please select a file to upload.");
      return;
    }

    const storageRef = ref(
      storage,
      `paymentReceipts/${selectedRequest.Request_id}/${receiptFile.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, receiptFile);
    setProgress(0); //resetting progress to 0 before starting new upload


    const resetProgress = () => setProgress(0);
    resetProgress();


    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Calculate upload progress as a percentage
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress); // Update progress state
        console.log(`Upload is ${Math.round(progress)}% done`);
      },
      (error) => {
        console.error("Error uploading file:", error);
        alert("Error uploading file. Please try again.");
        setProgress(0); // Reset progress on error
      },
      async () => {
        try {
          // Get the download URL of the uploaded file
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
    
          // Update the Firestore document with receipt URL and status
          await updateDoc(requestDocRef, {
            paymentReceiptURL: downloadURL,
            Status: "Payment Verification Pending",
          });
    
          console.log("Receipt uploaded and status updated successfully.");
          toast.success(
            "Receipt uploaded successfully and status updated to 'Payment Verification Pending'!"
          );
    
          // Close the modal after a successful upload and update
          setIsModalOpen(false);
    
          // Reset progress
          setProgress(0);
        } catch (error) {
          console.error("Error updating document in Firestore:", error);
          toast.error("Error saving receipt URL and updating status in the database.");
          setProgress(0); // Reset progress on Firestore update error
        }
      }
    );
    
  };

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    toggleModal();
  };

  const calculateLocationCounts = (packages) => {
    const counts = {};

    packages.forEach((pkg) => {
      if (pkg.Status !== "Ready to Ship" && (selectedStatusShop === "" || pkg.Status === selectedStatusShop)) {
        const location = pkg.Warehouse_Location;
        if (location) {
          if (!counts[location]) {
            counts[location] = 1;
          } else {
            counts[location] += 1;
          }
        }
      }
    });

    return counts;
};

  const [locationCounts, setLocationCounts] = useState({})

  // Calculate total packages by location (this runs only when receivedPackages changes)
  useEffect(() => {
    if (receivedPackages.length > 0) {
      const counts = calculateLocationCounts(receivedPackages); // Calculate with filtering
      setLocationCounts(counts); // Store filtered package counts by location
    }
  }, [receivedPackages, selectedStatusShop]);

   // Mockup for fetching packages data
   useEffect(() => {
    // Simulate fetching packages (replace with your actual fetching logic)
    const mockPackages = [
      { Warehouse_Location: 'USA' },
      { Warehouse_Location: 'UAE' },
      { Warehouse_Location: 'UK' },
      { Warehouse_Location: 'Canada' },
      { Warehouse_Location: 'India' },
      { Warehouse_Location: 'Australia' },
      { Warehouse_Location: 'China' },
      { Warehouse_Location: 'Malaysia' },
      { Warehouse_Location: 'Japan' },
      { Warehouse_Location: 'Singapore' },
    ];
    setReceivedPackages(mockPackages);
  }, []);

  // Filter the received packages based on selected location (if any)
const filteredPackages = selectedLocation
? receivedPackages.filter(pkg => pkg.Warehouse_Location === selectedLocation)
: receivedPackages;

  // Function to add 'https://' if it's not present
const getUrlWithProtocol = (url) => {
  // Check if the URL already starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`; // Prepend 'https://'
  }
  return url;
};

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserAuthenticated(true);
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setUserName(userData["User Name"]);
            setUserId(userData["User_ID"]);
            setAddress(userData.Address);

            // Fetch other required data
            await fetchBankDetails();
            await fetchReceivedPackages();
            await fetchShoppingRequests();
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setUserAuthenticated(false);
        setLoading(false); // Allow UI to render before redirection
        navigate("/signin"); // Redirect to sign-in page only when user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

//
// useEffect(() => {
//   if (!loading && !userAuthenticated) {
//     navigate("/signin"); // Redirect only if the user is not authenticated
//   }
// }, [loading, userAuthenticated, navigate]);

// Ensure the loading spinner or dashboard content is displayed correctly
  if (loading) {
    return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
    );
  }


if (!userAuthenticated) {
  // Prevent unnecessary rendering of the dashboard if the user is not authenticated
  return null;
}

  return (
    <div className="dashboard-container-main">
      {loading ? (//display loader if loading is true
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      ):(
        <div className="dashboard-container">
    <div className="welcome-section">
      <div className="welcomehead-container">
        <h1>Dashboard</h1>
      </div>
      <p>Eparcel Maldives Address (User ID included)</p>
      <div className="address-card-dashbaord">
        <p>
          <span style={{ fontSize: "25px", fontWeight: "700" }}>{userName}</span>
          <br />
          User ID: {userId} <br />
          {address.addressLine1} <br />
          {address.addressLine2} <br />
          {address.addressLine3} <br />
          {address.addressLine4} <br />
          {address.addressLine5} <br />
          {address.country}
        </p>
      </div>
    <div className="type-container">
      <button className="shopping-request-btn" onClick={toggleShoppingRequestModal}>
        Assisted Shopping Request
      </button>
      {/* Button to open modal */}
      <button className="shopOnline-btn" onClick={toggleShopOnlineModal} >
        Shop Online
      </button>

      {/* Conditionally render the modal */}
      {isShopOnlineModalOpen && (
        <ShopOnlineModal 
          isShopOnlineModalOpen={isShopOnlineModalOpen} 
          toggleModal={toggleShopOnlineModal}
          userId = {userId}
           
        />
      )}

      

      
      
    </div>
      <div className="packages_buttoon">
        <button className="footer-item" onClick={() => handleFooterClick("items")}>
          My Orders
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("packages")}>
          My Shipments
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("shopping")}>
          Shopping Requests
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("shop-online")}>
          Shop Online Orders
        </button>
      </div>
    </div>

    {activeTab === "items" && (
      <div className="items-tab-container">
          <div className="desc-tab">
          <i className="fas fa-info-circle"></i> &nbsp; Shows all items received at the warehouse and waiting to be shipped.
        </div>
        {/* Location-wise package counts */}
        <div className="location-counts-container">
        {Object.keys(locationCounts).length > 0 ? (
          Object.entries(locationCounts).map(([location, count]) => (
            <div key={location}>
              <p className="locp">{location}: {count} packages</p>
            </div>
          ))
        ) : (
          <p className="locp">No packages available.</p>
        )}
      </div>

          

          {/* Filter by Warehouse Location */}
          <div className="dbfilter-section">
      
        <label>Ship From</label>
        <select
          value={selectedLocation}
          onChange={(e) => {
            setSelectedLocation(e.target.value);
            setShowPopup(false); // Hide the popup when the location changes
          }}
        >
          <option
            style={{color:'#8F9191'}}
            value="" disabled
          >
            Select Location
          </option>
          <option value="Australia">Australia</option>
          <option value="Canada">Canada</option>
          <option value="China">China</option>
          <option value="India">India</option>
          <option value="Japan">Japan</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Singapore">Singapore</option>
          <option value="UAE">UAE</option>
          <option value="UK">UK</option>
          <option value="USA">USA</option>
        </select>
      </div>
      


    <div className="items-display">
      {selectedLocation && receivedPackages.length === 0 ? (

        <div className="info-error-items">
        <lord-icon
          src="https://cdn.lordicon.com/msyeyaka.json"
          trigger="loop"
          delay="1000"
          stroke="bold"
          colors="primary:#a1ddfd,secondary:#a1ddfd"
          style={{ width: "85px", height: "85px" }}
        ></lord-icon>
        <p>No items available for the selected location.</p>

      </div>

      ) : (
        <ul>
          {receivedPackages
            .filter(
              (item) =>
                item.Warehouse_Location === selectedLocation &&
                item.Status != "Ready to Ship"
            )
            .map((item) => (
              <li
                key={item.id}
                onClick={() => handleItemSelect(item.Order_ID)} // Handle item selection
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRequestIds.includes(item.Order_ID)
                    ? "#b2ebf2"
                    : "#e0f7fa", // Highlight selected items
                }}
              >
                <div>Tracking Number: {item.Tracking_Number} </div>
                <div>Warehouse Location: {item.Warehouse_Location} </div>
                <div>Order Number: {item.Order_ID}</div>
                <div>Registered on:  {new Date(item.createdAt.seconds * 1000).toLocaleString()}</div>
              </li>
            ))}
        </ul>
      )}
    </div>


    <div>
      {/* Button to send selected items to Firestore and open popup */}
      <button
            className="ship-items-btn"
            onClick={() => {
              // Check if any items are selected
              if (selectedRequestIds.length > 0) {
                // If items are selected, show the popup
                setShowPopup(true);
              } else {
                // If no items are selected, trigger the warning and do not show the popup
                handleRequestToShip();
              }
            }}
            disabled={!selectedLocation}
          >
          Request to Ship Your Items
      </button>


      {/* Show the ShipYourItem popup if the button is clicked */}
      {showPopup &&
      <ShipYourItem
        onClose={() => setShowPopup(false)}
        selectedRequestIds={selectedRequestIds}
        />} {/* Close the popup when finished */}
    </div>
  </div>
)}


{activeTab === "packages" && (
  <div className="packages-popup-overlay">
    <div className="packages-popup-content">
      <h2>Your Packages Identity</h2>
      <div className="desc-tab">
          <i className="fas fa-info-circle"></i> 
              &nbsp; Displays packages ready for shipping until they are delivered to you.
      </div>

      <div className="filter-container">
  <div className="date-filter">
    <label>From: </label>
    <input
      type="date"
      value={startDate}
      onChange={handleStartDateChange}
      max={today}
    />
    <label>To: </label>
    <input
      type="date"
      value={endDate}
      onChange={handleEndDateChange}
      max={today}
    />
  </div>
  <div className="status-filter">
    <label>Warehouse: </label>
    <select
      value={warehouseFilter}
      onChange={(e) => setWarehouseFilter(e.target.value)}
    >
      <option value="All">All</option>
      <option value="Australia">Australia</option>
      <option value="Canada">Canada</option>
      <option value="China">China</option>
      <option value="India">India</option>
      <option value="Japan">Japan</option>
      <option value="Malaysia">Malaysia</option>
      <option value="Singapore">Singapore</option>
      <option value="UAE">UAE</option>
      <option value="UK">UK</option>
      <option value="USA">USA</option>
    </select>
  </div>
  <button className="reset-filters-btn" onClick={resetFilters}>
    Reset
  </button>
</div>
 
      
      {loading ? (
        <div className="loader">Loading...</div>
      ) : filteredShippingData.length === 0 ? (
        <div className="info-error-items">
          <lord-icon
            src="https://cdn.lordicon.com/msyeyaka.json"
            trigger="loop"
            delay="1000"
            stroke="bold"
            colors="primary:#2D6992,secondary:#2D6992"
            style={{ width: "85px", height: "85px" }}
          ></lord-icon>
          <p style={{color:"#2D6992"}}>No items available.</p>
        </div>
      ) : (
        <div className="shipping-items-table-flex">
          <table className="shipping-items-table">
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Billable Weight</th>
                <th>Warehouse Location</th>
                <th>Created At</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
  {filteredShippingData
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map((item) => (
      <React.Fragment key={item.id}>
        <tr onClick={() => toggleExpand(item.id)}>
          <td>{item.request_ids}</td>
          <td>{item.total_billable_weight}</td>
          <td>{item.warehouse_location}</td>
          <td>{new Date(item.createdAt.seconds * 1000).toLocaleString()}</td>
          <td style={{ justifyItems: "center" }}>
            <div className="MoreLessContainer">
              <button className="btnMoreLess" onClick={() => toggleExpand(item.id)}>
                {expandedItemId === item.id ? (
                  <img
                    src="/images/angle-small-up.png"
                    alt="Less"
                    title="Less"
                    width="30"
                    height="30"
                  />
                ) : (
                  <img
                    src="/images/angle-small-down.png"
                    alt="More"
                    title="More"
                    width="30"
                    height="30"
                  />
                )}
              </button>
            </div>
          </td>
        </tr>
        {expandedItemId === item.id && (
          <tr className="expanded-row">
            <td colSpan="5">
              <div className="expanded-content">
                <DetailsCarousel
                  item={item}
                  receivedDetails={receivedDetails}
                />
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    ))}
</tbody>


          </table>
          {/* Pagination Controls */}
          <div className="pagination-containerPackages">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="arrow-btn"
              >
                <i className="fas fa-arrow-left"></i> {/* Left arrow icon */}
              </button>

              {pageNumbersPackages.map((page) => (
                <button
                  key={page}
                  className={`page-btn ${page === currentPage ? "active" : ""}`}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              ))}

            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPagesPackages}
              className="arrow-btn"
            >
              <i className="fas fa-arrow-right"></i> {/* Right arrow icon */}
            </button>
          </div>

        </div>

      )}
    </div>

  </div>
)}





    {activeTab === "shopping" && (
        <div className="shopping-requests-container">
          <div className="shopping-requests-section">
            <h2>Your Shopping Requests</h2>
            <div className="desc-tab">
                <i className="fas fa-info-circle"></i> 
                    &nbsp; Tracks your assisted shopping requests, from placing the order to confirming the purchase.
            </div>

            {/* Search bar and filter */}
            <div className="search-bar-container">
              <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by Purchase Name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {/* Dropdown for filtering by status */}
              <div className="status-filter">
                <select
                    value={selectedStatusShop}
                    onChange={(e) => setSelectedStatusShop(e.target.value)}
                >
                  <option value="">All Packages</option>
                  <option value="Pending">Pending</option>
                  <option value="Agent Review Ongoing">Agent Review Ongoing</option>
                  <option value="Item Rejected">Item Rejected</option>
                  <option value="Payment for Items Pending">Payment for Items Pending</option>
                  <option value="Payment Verification Pending">Payment Verification Pending</option>
                  <option value="Payment Rejected">Payment Rejected</option>
                  <option value="Payment Request Completed">Payment Request Completed</option>
                  <option value="Request Completed">Request Completed</option>
                </select>
              </div>
            </div>

            {/* Range Info */}
            <div className="range-info">
              {startIndex}-{endIndex} of {filteredAndSortedRequests.length}
            </div>

            {/* Display filtered shopping requests */}
            {currentRequests.length === 0 ? (
                <div className="info-error">
                  <lord-icon
                      src="https://cdn.lordicon.com/msyeyaka.json"
                      trigger="loop"
                      delay="1000"
                      stroke="bold"
                      colors="primary:#112151,secondary:#0056b3"
                      style={{width: "85px", height: "85px"}}
                  ></lord-icon>
                  <p>No shopping requests found!</p>
                </div>
            ) : (
                <div className="shopping-requests-grid">
                  {currentRequests.map((request) => (
                      <div className="shopping-request-card" key={request.id}>
                        <div className="request-header">
                          <p><strong>Purchase ID:</strong> {request.Request_id}</p>
                          <p><strong>Purchase Name:</strong> {request.Request_name}</p>
              </div>
              <div className="request-details">
                <p><strong>Created On:</strong> {new Date(request.createdAt.seconds * 1000).toLocaleString()}</p>
                <p><strong>Status:</strong> {request.Status || "Pending"}</p>
                {request.Order_ID && <p><strong>Order ID:</strong> {request.Order_ID}</p>}
              </div>
              <div className="request-footer">
                <button className="view-details-btn" onClick={() => handleViewDetails(request)}>
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

           {/* Pagination Controls */}
<div className="pagination-container">
  <button
    onClick={() => setCurrentPage(currentPage - 1)}
    disabled={currentPage === 1}
    className="arrow-btn"
  >
    <i className="fas fa-arrow-left"></i> {/* Left arrow icon */}
  </button>

  {pageNumbers.map((page, index) => (
    <button
      key={index}
      className={`page-btn ${page === currentPage ? "active" : ""}`}
      onClick={() => typeof page === "number" && setCurrentPage(page)}
      disabled={page === "..."}
    >
      {page}
    </button>
  ))}

  <button
    onClick={() => setCurrentPage(currentPage + 1)}
    disabled={currentPage === totalPages}
    className="arrow-btn"
  >
    <i className="fas fa-arrow-right"></i> {/* Right arrow icon */}
  </button>
</div>

    </div>
  </div>
)}




          {activeTab === "shop-online" && (
              <div className="packages-popup-overlay">
                <div className="packages-popup-content">
                  <h2>Your Shop Online Orders</h2>
                  
                  <div className="desc-tab">
                      <i className="fas fa-info-circle"></i>
                    &nbsp; Displays the shipments of your online orders.
                  </div>
                  {/*filtering section*/}
                    <div className="filter-container">
                        <div className="date-filter">
                          <label>From: </label>
                          <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            max={today}
                          />
                          <label>To: </label>
                          <input
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            max={today}
                          />
                        </div>
                        <div className="status-filter">
                          <label>Status: </label>
                          <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                          >
                            <option value="All">All</option>
                            <option value="Received">Received</option>
                            <option value="Pending">Pending</option>
                          </select>
                        </div>
                        <button 
                          className="reset-filters-btn" 
                          onClick={resetFilters}>
                          
                          Reset 
                        </button>
                      </div>

                   
                  {loading ? (
                      <div className="loader">Loading...</div>
                  ) : filteredItems.length === 0 ? (
                    <div className="info-error-items">
                    <lord-icon
                      src="https://cdn.lordicon.com/msyeyaka.json"
                      trigger="loop"
                      delay="1000"
                      stroke="bold"
                      colors="primary:#2D6992,secondary:#2D6992"
                      style={{ width: "85px", height: "85px" }}
                    ></lord-icon>
                    <p style={{color:"#2D6992"}}>No items available.</p>
                  </div>
                  ) : (
                      <div className="shipping-items-table-flex">
                        <table className="shipping-items-table">
                          <thead>
                          <tr>
                            <th>Tracking No</th>
                            <th>Order Date</th>
                            <th>Item Name</th>
                            <th>Description</th>
                            <th>Warehouse location</th>
                            <th>Status</th>
                            <th>Invoice</th>
                            
                          </tr>
                          </thead>
                          <tbody>
                            {filteredItems
                              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                              .map((item) => (
                                <React.Fragment key={item.id}>
                                  <tr onClick={() => toggleExpand(item.id)}>
                                    <td>{item.trackingNo}</td>
                                    <td>{item.orderDate}</td>
                                    <td>{item.itemName}</td>
                                    <td>{item.description}</td>
                                    <td>{item.warehouseLocation}</td>
                                    <td>{item.status}</td>
                                    <td>
                                      <a href={item.invoiceUrl}>
                                        <center>
                                          <lord-icon
                                            src="https://cdn.lordicon.com/gsjfryhc.json"
                                            trigger="hover"
                                            stroke="bold"
                                            colors="primary:#31709A,secondary:#31709A"
                                            style={{ width: "25px", height: "25px" }}
                                          ></lord-icon>
                                        </center>
                                      </a>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                          </tbody>

                        </table>
                        {/* Pagination Controls */}
                        <div className="pagination-containerPackages">
                          <button
                              onClick={() => setCurrentPage(currentPage - 1)}
                              disabled={currentPage === 1}
                              className="arrow-btn"
                          >
                            <i className="fas fa-arrow-left"></i> {/* Left arrow icon */}
                          </button>

                          {pageNumbersPackages.map((page) => (
                              <button
                                  key={page}
                                  className={`page-btn ${page === currentPage ? "active" : ""}`}
                                  onClick={() => setCurrentPage(page)}
                              >
                                {page}
                              </button>
                          ))}

                          <button
                              onClick={() => setCurrentPage(currentPage + 1)}
                              disabled={currentPage === totalPagesPackages}
                              className="arrow-btn"
                          >
                            <i className="fas fa-arrow-right"></i> {/* Right arrow icon */}
                          </button>
                        </div>

                      </div>

                  )}
                </div>

              </div>
          )}

{isShoppingRequestModalOpen && (
  <div className="modal-overlay">
    <div className="modal-details">
      <button className="close-modal" onClick={toggleShoppingRequestModal}>
        ×
      </button>
      <h2>Let us Shop for You</h2>
      <div className="form-group">
        <p className="info">Please provide a name for your package</p>
        <input
          type="text"
          value={shoppingRequestName}
          onChange={(e) => setShoppingRequestName(e.target.value)}
          placeholder="Please give a Name (eg: Nike order)"
          style={{ borderRadius: '15px' }}
          required
        />
      </div>

      {items.map((item, index) => (
        <div className="item-entry" key={item.id}> {/* Use item.id for unique key */}

          <input

            type="text"
            value={item.url}
            onChange={(e) => handleItemChange(index, "url", e.target.value)}
            placeholder="Please enter the URL of the item"
            className={item.url.trim() === "" ? "input-error" : ""}
            style={{ borderRadius: '15px' }}
          />
          <input
            type="text"
            value={item.Description}
            onChange={(e) => handleItemChange(index, "Description", e.target.value)}
            placeholder="Size, Color"
            className={item.Description.trim() === "" ? "input-error" : ""}
            style={{ borderRadius: '15px' }}
          />


        </div>
      ))}

      <div className="terms-and-conditions">
        <input
          type="checkbox"
          checked={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
        <label>
          I agree with the <a href="/terms-and-conditions">terms and conditions</a>
        </label>

      </div>

      <button className="request-btn"
              onClick={handleSubmitRequestToPurchase}
              disabled={loading}>
        {loading ? "Processing..." : "Request to Purchase"}
      </button>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
    </div>

  </div>
)}


{isModalOpen && selectedRequest && (
  <div className="modal-overlay">
    <div className="modal-details">
      <button className="close-modal" onClick={toggleModal}>
        ×
      </button>
      <h2>Shipment Details</h2>
      <div className="modal-shipment-details-bg">

        <p>
          <strong>Purchase ID:</strong> {selectedRequest.Request_id}
        </p>
        <p>
          <strong>Purchase Name:</strong> {selectedRequest.Request_name}
        </p>
        <p>
          <strong>Purchase Status:</strong> {selectedRequest.Status}
        </p>
        <p>
          <strong>Service Type:</strong> {selectedRequest.Service_type}
        </p>
        <p>
          <strong>Created On:</strong>{" "}
          {selectedRequest.createdAt
            ? new Date(selectedRequest.createdAt.seconds * 1000).toLocaleString()
            : "Invalid Date"}
        </p>

        {selectedRequest.Order_ID && (
  <p>
    <strong>Order ID:</strong> {selectedRequest.Order_ID}
  </p>
)}
      </div>
      {(selectedRequest.Status === "Item Rejected" || selectedRequest.Status === "Payment Rejected") && selectedRequest.Admin_Remark && (
  <p>
    <strong>Admin Remark:</strong> {selectedRequest.Admin_Remark}
  </p>
)}


      <div className="modal-shipment-details">
        {selectedRequest.product_reference_url &&
          selectedRequest.product_reference_url.length > 0 && (
            <>
              <h3>Requested Items (URLs)</h3>
              
              <div className="table-container">

              <table className="table">
  <thead>
    <tr>
      <th>URL</th>
      <th>Description</th>
      {selectedRequest.Unit_price && selectedRequest.Unit_price.some((price) => price) && <th>Unit Price</th>}
      {selectedRequest.QTY && selectedRequest.QTY.some((qty) => qty) && <th>QTY</th>}
      {selectedRequest.Delivery_charge && selectedRequest.Delivery_charge.some((charge) => charge) && (
        <th>Delivery Charge</th>
      )}
      {selectedRequest.Tax_amount && selectedRequest.Tax_amount.some((tax) => tax) && <th>Tax Amount</th>}
      {selectedRequest.Total_price && selectedRequest.Total_price.some((total) => total) && <th>Total Price (MVR)</th>}
    </tr>
  </thead>
  <tbody>
    {selectedRequest.product_reference_url.map((url, index) => (
      <tr key={index}>
        <td>
        <a href={getUrlWithProtocol(url)} target="_blank" rel="noopener noreferrer" title="Open Product Link">
          <center>
                            <lord-icon
                              src="https://cdn.lordicon.com/gsjfryhc.json"
                              trigger="hover"
                              stroke="bold"
                              colors="primary:#0056B3,secondary:#0056B3"
                              style={{ width: "30px", height: "30px" }}
                              ></lord-icon> </center>
                            </a>
        </td>
        <td>{selectedRequest.Description[index]}</td>

        {/* Conditionally display Unit Price if not empty */}
        {selectedRequest.Unit_price && selectedRequest.Unit_price[index] !== undefined && (
          <td>{selectedRequest.Unit_price[index]}</td>
        )}

        {/* Conditionally display QTY if not empty */}
        {selectedRequest.QTY && selectedRequest.QTY[index] !== undefined && <td>{selectedRequest.QTY[index]}</td>}

        {/* Conditionally display Delivery Charge if not empty */}
        {selectedRequest.Delivery_charge && selectedRequest.Delivery_charge[index] !== undefined && (
          <td>{selectedRequest.Delivery_charge[index]}</td>
        )}

        {/* Conditionally display Tax Amount if not empty */}
        {selectedRequest.Tax_amount && selectedRequest.Tax_amount[index] !== undefined && (
          <td>{selectedRequest.Tax_amount[index]}</td>
        )}

        {/* Conditionally display Total Price if not empty */}
        {selectedRequest.Total_price && selectedRequest.Total_price[index] !== undefined && (
          <td>{selectedRequest.Total_price[index]} MVR</td>
        )}
      </tr>
    ))}
  </tbody>
</table>
        </div>
        {/* Calculate and display Total Price + 5% */}
          {selectedRequest.Total_price && selectedRequest.Total_price.length > 0 && (
            <div className="total-price-summary">
              <p1>
                <strong>Total Amount to Pay:</strong>{' '}
                {(
                  selectedRequest.Total_price.reduce((sum, price) => sum + price, 0) * 1.05
                ).toFixed(2)}{' '}
                MVR
              </p1>
              <p2>(Includes 5% extra charge)</p2>
            </div>
          )}
      </>
    )}

                {/* Conditionally render Edit button based on Service_type */}
{(selectedRequest.Service_type === "Shop online" ||
  selectedRequest.Status === "Pending" ||
  selectedRequest.Status === "Item Rejected") && (
    <button className="edit-request-btn" onClick={handleEditRequest}>
      Edit Purchase Request
    </button>
)}

              {/* Conditionally render Payment Instructions and Upload Payment Receipt for Payment for Items Pending */}
{(selectedRequest.Status === "Payment for Items Pending" || selectedRequest.Status === "Payment Rejected") && (
  <>
  <div className="icon_payment">
    <lord-icon
                      src="https://cdn.lordicon.com/ysqeagpz.json"
                      trigger="loop"
                      delay="2000"
                      stroke="bold"
                      colors="primary:#112151,secondary:#0056b3"
                      style={{ width: "50px", height: "50px" }}
                    ></lord-icon>
    <h3>Payment Instructions</h3>
    </div>
  <div className="payment-instructions-container">
    <div className="payment-instructions">
      {bankDetails.length === 0 ? (
        <p>No bank details available</p>
      ) : (
        bankDetails.map((bank, index) => (
          <p key={index}>
            <strong>Account {index + 1}:</strong> {bank.accountNumber} ({bank.currency}) -{" "}
            {bank.bankName}
          </p>
        ))
      )}
    </div>
  </div>
  <div className="upload-container">
    <h3>Upload Payment Receipt</h3>
    <div className="upload-receipt">
      <input
        type="file"
        id="paymentReceipt"
        name="paymentReceipt"
        accept=".pdf, .png, .jpg, .jpeg"
        onChange={(e) => setReceiptFile(e.target.files[0])}
      />

      {progress > 0 && (
        <div className="progress-bar-container">
          <div className="progress-bar-pay" style={{ width: `${progress}%` }}>
            {Math.round(progress)}%
          </div>
        </div>
      )}
    <div className="btnParentContainer">
     <div className="upload-btn-container">
      <button className="upload-btn" onClick={handleReceiptUpload}>
        Upload
      </button>
      
     </div>
     <button className="modalclose-btn" onClick={toggleModal}>
        Cancel
      </button>
    </div>
    </div>
  </div>
  </>
)}

              </div>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <div className="modal-overlay">
            <div className="modal-details">
              <button className="close-modal" onClick={toggleEditModal}>
                ×
              </button>
              <h2>Edit Your Requests</h2>
              <p>Note: Editing package will revert the status.</p>
              {items.map((item, index) => (
                <div className="item-entry" key={index}>
                  <p>{index + 1}</p>
                  <input
                    type="text"
                    value={item.url}
                    onChange={(e) => handleItemChange(index, "url", e.target.value)}
                    placeholder="Item/Product URL"
                  />
                  <input
                    type="text"
                    value={item.Description}
                    onChange={(e) => handleItemChange(index, "Description", e.target.value)}
                    placeholder="Description"
                  />
                  <button className="delete-btn" title="Delete" onClick={() => deleteItem(item.id)}>
                    <lord-icon
                      src="https://cdn.lordicon.com/hwjcdycb.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#E33F31,secondary:#E33F31"
                      state="hover-slide"
                      style={{ width: "30px", height: "30px" }}
                    ></lord-icon>
                  </button>
                </div>
              ))}

              <div className="button-container">

                <button className="request-btn" onClick={handleSaveChanges}>
                  <lord-icon
                    src="https://cdn.lordicon.com/tsrgicte.json"
                    trigger="hover"
                    stroke="bold"
                    colors="primary:#ffffff,secondary:#ffffff"
                    state="hover-slide"
                    style={{ width: "50px", height: "50px" }}
                  ></lord-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )}


      <ToastContainer/>
      <Footer />
    </div>
  );
};

export default Dashboard;